.menu-icon {
  display: flex;
  align-items: center;

  cursor: pointer;
}

  .menu-icon__icon {
    margin-right: 11px;

    width: 24px;
    height: 24px;

    fill: #59358c;
  }

  @media (max-width: 767.98px) {

  .menu-icon__icon--mobile {
      margin-right: 0
  }
    }

  .menu-icon__title {
    font-family: Myriad Pro;
    font-weight: bold;
    font-size: 16px;
    line-height: 110%;

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #59358c;
  }

  @media (max-width: 767.98px) {

  .menu-icon__title--mobile {
      display: none
  }
    }
