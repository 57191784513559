@font-face {
  font-weight: 600;
  font-family: 'Myriad Pro';
  font-style: bold;

  font-display: swap;
  src: url('/fonts/MyriadPro-Bold.woff') format('woff'),
    url('/fonts/MyriadPro-Bold.woff2') format('woff2');
}

@font-face {
  font-weight: 400;
  font-family: 'Myriad Pro';
  font-style: normal;

  font-display: swap;
  src: url('/fonts/MyriadPro-Regular.woff') format('woff'),
    url('/fonts/MyriadPro-Regular.woff2') format('woff2');
}

* {
  margin: 0;
  padding: 0;

  border: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

html,
body {
  min-width: 320px;
  /* height: 100%; */
}

body {
  line-height: 1;
  font-family: 'Myriad Pro';

  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: 'Myriad Pro';
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;

  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

body {
  font-size: 14px;
  color: #000000;
}

body._lock {
    overflow: hidden;
  }

.page-title {
  font-weight: bold;
  font-size: 64px;

  line-height: 120%;

  color: #59358c;
}

@media (max-width: 1200px) {

.page-title {
    font-size: calc(40px + (64 - 40) * ((100vw - 375px) / (1200 - 375)))
}
  }

.materials {
  display: flex;
  flex-direction: column;
}

.materials__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;

    flex-wrap: wrap;
    gap: 30px;
  }

@media (max-width: 1200px) {

.materials__list {
      margin-bottom: calc(24px + (48 - 24) * ((100vw - 375px) / (1200 - 375)))
  }
    }

@media (max-width: 767.98px) {

.materials__list {
      flex-direction: column
  }
    }

.materials__item {
    width: calc(33% - 17px);
  }

@media (max-width: 926px) {

.materials__item {
      width: 100%
  }
    }

@media (max-width: 767.98px) {

.materials__item {
      margin-bottom: 16px
  }
    }

.materials__item:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

.materials__link {
    position: relative;

    display: flex;
    flex-direction: column;
    /* width: 100%;
        max-width: 370px; */
    height: 100%;
    overflow: hidden;
  }

@media (max-width: 991.98px) {

.materials__link._banner {
        height: auto
    }
      }

@media (max-width: 991.98px) {

.materials__link._banner .materials__img {
          height: auto
      }
        }

@media (max-width: 991.98px) {

.materials__link._banner:before {
          display: none
      }
        }

@media (max-width: 767.98px) {

.materials__link {
      max-width: 100%
  }
    }

@media (max-width: 479.98px) {

.materials__link {
      height: 220px;
      overflow: hidden
  }
    }

.materials__link:hover .materials__common {
      background: #59358c;
    }

@media (max-width: 767.98px) {

.materials__link:hover .materials__common {
        background: transparent
    }
      }

.materials__link:hover .materials__img {
      background: #59358c;
      transform: scale(1.05);
    }

@media (max-width: 767.98px) {

.materials__link::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: linear-gradient(
          180deg,
          rgba(89, 53, 140, 0) 0%,
          #282828 100%
        )
    }
      }

.materials__link:hover::before {
      background: linear-gradient(
        180deg,
        rgba(89, 53, 140, 0) 0%,
        #59358c 100%
      );
    }

.materials__img {
    width: 100%;
    height: 220px;

    transition: all 0.4s ease;

    object-fit: cover;
  }

.materials__common {
    background: #282828;
    flex: 1 1 auto;
    width: 100%;
    padding: 19px 24px;
    transition: all 0.4s ease;
  }

@media (max-width: 767.98px) {

.materials__common {
      position: absolute;
      bottom: 0;
      left: 0;

      background: transparent
  }
    }

.materials__more {
    margin-top: auto;
  }

.materials__date {
    color: #fff;
    font-size: 14px;
  }

.materials__title {
    font-weight: bold;
    line-height: 120%;
    color: #ffffff;

    font-size: 24px;
    margin-bottom: 10px;
  }

@media (max-width: 1200px) {

.materials__title {
      /* font-size: calc(
                12px + (18 - 12) * ((100vw - 375px) / (1200 - 375))
            ); */
      font-size: 18px
  }
    }

@media (max-width: 767.98px) {

.materials__title {
      /* position: absolute;
            bottom: 0;
            left: 0;

            background: transparent; */
  }
    }

.materials__btn {
    margin: 0 auto;
  }

.post-person {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767.98px) {

.post-person {
    flex-direction: row
}
  }

.post-person__wrapper-img {
    flex-shrink: 0;
    margin-bottom: 24px;
    width: 224px;
    height: 224px;

    border-radius: 50%;

    overflow: hidden;
  }

@media (max-width: 1200px) {

.post-person__wrapper-img {
      width: calc(80px + (224 - 80) * ((100vw - 375px) / (1200 - 375)));
      height: calc(80px + (224 - 80) * ((100vw - 375px) / (1200 - 375)))
  }
    }

@media (max-width: 767.98px) {

.post-person__wrapper-img {
      margin-bottom: 0;
      margin-right: 24px
  }
    }

.post-person__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

.post-person__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;

    text-align: center;

    color: #282828;
  }

@media (max-width: 1200px) {

.post-person__title {
      font-size: calc(16px + (16 - 16) * ((100vw - 375px) / (1200 - 375)) ;)
  }
    }

@media (max-width: 767.98px) {

.post-person__title {
      text-align: left
  }
    }

.post-person__title:hover {
      color: #59358c;
    }

.post-video__link {
    position: relative;

    display: block;
    height: 100%;

    cursor: pointer;
  }

.post-video__link::after,
    .post-video__link::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transition: all 0.4s ease;
    }

.post-video__link::after {
      background: linear-gradient(
        180deg,
        rgba(89, 53, 140, 0) 0%,
        #282828 100%
      );
      opacity: 1;
    }

.post-video__link::before {
      background: linear-gradient(
        180deg,
        rgba(89, 53, 140, 0) 0%,
        #59358c 100%
      );
      opacity: 0;
    }

.post-video__link:hover::after {
      opacity: 0;
    }

.post-video__link:hover::before {
      opacity: 1;
    }

.post-video__video {
    width: 100%;
  }

.post-video__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

.post-video__common {
    position: absolute;
    bottom: 35px;
    left: 40px;
    right: 40px;
    z-index: 1;
  }

@media (max-width: 479.98px) {

.post-video__common {
      bottom: 28px;
      left: 32px
  }
    }

.post-video__title {
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 10px;
  }

@media (max-width: 1200px) {

.post-video__title {
      font-size: 18px
  }
    }

.post-video__date {
    color: #fff;
  }

.post-video:hover .video-icon {
    background: #d71a1a;
  }

.post-video:hover .video-icon__triangle {
      border-color: transparent transparent transparent #ffffff;
    }

._hidden {
  height: 1px;
  opacity: 0;
  overflow: hidden;
}

/* .app {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    color: #000000;
} */

#mount {
  /* height: 100%; */
}

.wrapper {
  /* display: flex;
    flex-direction: column; */
}

/* width: 100%;
    min-height: 100%; */

@media (max-width: 1200px) {

.wrapper--branding {
      margin-top: calc(
        -69px + (-258 - 69) * ((100vw - 375px) / (1200 - 375)) ;
      )
  }
    }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 767.98px) {

.container {
    max-width: none;
    padding: 0
}
  }

@media (max-width: 767.98px) {

.container--page {
    padding: 0 16px
}
  }

.clear {
  height: 50px;
}

.articles.page__articles._wide {
  display: flex;
  flex-wrap: wrap;
}

.articles.page__articles._wide .articles__more {
  margin-left: auto;
}

.articles.page__articles._wide .articles__post {
  width: 100%;
}

.articles.page__articles._wide .post__images {
  max-width: 100%;
}

.post-video__link .post-video__img {
}

@media (max-width: 767.98px) {

.card--m {
    grid-column: span 6;
    height: auto
}
  }

@media (min-width: 767.98px) {

.card--m .materials__img {
      height: 310px
  }
    }

.card--m .materials__title {
    font-size: 24px;
  }

/* position: absolute;
        height: 83px;
        bottom: 0;*/

@media (max-width: 767.98px) {

.card--m .materials__title {
      font-size: 18px
  }
    }

.post__date + .more.news__more {
  margin-top: 10px;
}

.card__wrapper {
  min-height: 100%;
}

.block-tabs__item a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 991px) {
  .post-video__link {
    min-height: 305px;
  }
}

.others.page__others .others__wrapper {
  gap: 30px;
}

.others.page__others .others__quiz {
  margin-right: 0;
  flex: 0 1 50%;
}

article.card {
  position: relative;
}

li.materials__item {
  position: relative;
}
