.tag {
  position: absolute;
  top: 24px;
  left: -8px;
  z-index: 2;

  padding: 8px 16px 4px;

  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  color: #282828;
  text-transform: uppercase;

  background: #ffc56e;
  border-radius: 4px;
}

  @media (max-width: 991.98px) {.tag {
    top: 16px
}
  }

  .tag.exclusive {
    color: #ffffff;
    background: #d71a1a;
  }

.tag--mobile {
  top: 8px;
  left: 9px;

  display: none;
  padding: 8px 11px 4px;

  font-size: 14px;
  line-height: 110%;
  letter-spacing: -1px;
}

@media (max-width: 991.98px) {

.tag--mobile {
    top: 8px
}
  }

@media (max-width: 479.98px) {

.tag--mobile {
    display: block
}
  }

.tag--subpost {
  top: 16px;
}

.tag--red {
  color: #ffffff;

  background: #d71a1a;
}

.tag--white {
  color: #c0c0c0;

  background: #f8f8f8;
}
