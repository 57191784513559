.post-newsfeed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 92px; */

  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
}

  @media (max-width: 550px) {.post-newsfeed {
    height: auto;
    margin-bottom: 7px
}
  }

  .post-newsfeed__title {
    color: #282828;

    transition: all 0.2s linear;
    min-height: 66px;
    margin-bottom: 8px;
  }

  @media (max-width: 1200px) {

  .post-newsfeed__title {
      min-height: calc(0px + (66 - 0) * ((100vw - 375px) / (1200 - 375)));
      margin-bottom: calc(5px + (8 - 5) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .post-newsfeed__title:hover {
      color: #8246b8;
    }

  .post-newsfeed__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #686868;
    letter-spacing: -0.8px;

    opacity: 0.5;
  }

.post-newsfeed--main {
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
}

.post-newsfeed--main .post-newsfeed__title {
    margin-bottom: 17px;

    color: #59358c;

    transition: all 0.2s linear;
  }

.post-newsfeed--main .post-newsfeed__title:hover {
      color: #8246b8;
    }

.post-newsfeed--author-item {
  width: 100%;
  max-width: 276px;
  height: auto;
}

.post-newsfeed--author-item .post-newsfeed__title {
      min-height: auto;

      font-size: 16px;
      line-height: 19px;
      color: #59358c;
      letter-spacing: -0.6px;
    }

.post-newsfeed--author-item .post-newsfeed__title:hover {
        color: #8246b8;
      }
