.submenu-items {
  position: absolute;
  top: 0;
  left: 0;

  display: none;
  width: 100%;
  min-width: 250px;

  background-color: #59358c;

  transform: translate(0px, 43px);

  z-index: 2;
}

  .submenu-items__item:hover {
      background: #8246b8;
    }

  .submenu-items__link {
    display: block;
    padding: 11px 16px;
    font-size: 16px;
    line-height: 110%;

    letter-spacing: 0.015em;

    color: #ffffff;
  }

.submenu__list {
    display: flex;
    align-items: center;
    padding: 16px 0;
  }

.submenu__item {
    margin-right: 24px;
  }

.submenu__item:last-child {
      margin-right: 0;
    }

.submenu__item._parent {
      position: relative;
      z-index: 20;
    }

.submenu__item._parent > span {
        font-size: 16px;
        line-height: 110%;
        color: #59358c;
        letter-spacing: 0.06px;
        padding-right: 33px;
      }

.submenu__item._parent > span::before {
          content: '';
          width: 1.5px;
          height: 7px;
          background: #59358c;
          position: absolute;
          right: 19px;
          top: 50%;
          margin-top: -4px;
          background-size: contain;
          transform: rotate(-45deg);
        }

.submenu__item._parent > span::after {
          content: '';
          width: 1.5px;
          height: 7px;
          background: #59358c;
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -4px;
          background-size: contain;
          transform: rotate(45deg);
        }

.submenu__item._parent > ul {
        position: absolute;
        left: 0;
        top: 100%;
        padding-top: 15px;
        background: #fff;
        margin-left: -15px;

        display: none;
      }

.submenu__item._parent:hover > ul {
          display: block;
        }

.submenu__item._parent li a {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 110%;
          letter-spacing: 0.015em;
          color: #59358c;
          padding: 13px 15px 13px;
          display: block;
          text-decoration: none;
          background: #fff;
          transition: 0.3s;
        }

.submenu__item._parent li a:hover {
            background: #a77ccd;
            color: #fff;
          }

.submenu__link {
    font-size: 16px;
    line-height: 110%;
    color: #59358c;
    letter-spacing: 0.06px;
  }

.submenu__link:hover {
      opacity: 0.9;
    }

@media (max-width: 991.98px) {

.submenu--mobile {
    display: none
}
  }

.submenu-items {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: none;
  width: 100%;

  background-color: #59358c;
  transform: translate(0px, 43px);
}

.submenu-items__item:hover {
      background: #8246b8;
    }

.submenu-items__link {
    display: block;
    padding: 11px 16px;

    font-size: 16px;
    line-height: 110%;
    color: #ffffff;
    letter-spacing: 0.015em;
  }
