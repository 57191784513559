.footer-social__title {
    margin-bottom: 15px;

    font-size: 14px;
    line-height: 110%;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #ffffff;
  }

    @media (max-width: 767.98px) {.footer-social__title {
      display: none
  }
    }
  .footer-social__list {
    display: grid;
    grid-template-columns: repeat(4, minmax(24px, 1fr));
    gap: 16px 13px;
  }
  @media (max-width: 767.98px) {
  .footer-social__list {
      gap: 16px 25px
  }
    }
  .footer-social__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 30px;
    height: 30px;

    background: #ffffff;
    border-radius: 50%;

    transform: all 0.3 linear;
  }
  @media (max-width: 992px) {
  .footer-social__link {
      width: 40px;
      height: 40px
  }
    }
  .footer-social__icon {
    fill: #59358c;
    width: 20px;
    height: 20px;
  }
  .footer-social__icon--vk {
    width: 17px;
    height: 8px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--vk {
      height: 12px;
      width: 23px
  }
    }
  .footer-social__icon--facebook {
    height: 15px;
    width: 7.5px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--facebook {
      height: 25px;
      width: 12.5px
  }
    }
  .footer-social__icon--odn {
    height: 19px;
    width: 11px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--odn {
      height: 25px;
      width: 14.5px
  }
    }
  .footer-social__icon--telegram {
    height: 16px;
    width: 14px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--telegram {
      height: 18px;
      width: 22px
  }
    }
  .footer-social__icon--youtube {
    height: 10px;
    width: 15px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--youtube {
      height: 13.3px;
      width: 20px
  }
    }
  .footer-social__icon--rrs {
    height: 15px;
    width: 15px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--rrs {
      height: 20px;
      width: 20px
  }
    }
  .footer-social__icon--mail {
    width: 17px;
    height: 12px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--mail {
      height: 16.7px;
      width: 23.3px
  }
    }
  .footer-social__icon--instagram {
    height: 11px;
    width: 11px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--instagram {
      height: 18.3px;
      width: 18.3px
  }
    }
  .footer-social__icon--twitter {
    height: 10px;
    width: 12px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--twitter {
      height: 16.6px;
      width: 20px
  }
    }
  .footer-social__icon--zen {
    height: 30px;
    width: 30px;
  }
  @media (max-width: 992px) {
  .footer-social__icon--zen {
      height: 40px;
      width: 40px
  }
    }
