.promotions {
  margin-left: auto;
  overflow: hidden;
  max-width: 300px;
  position: relative;
  width: 300px;
  display: flex;
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
  height: 16px;
}

  .promotions__list {
    overflow: hidden;
    display: flex;
    position: absolute;
  }

  .promotions__list > div {
      animation: marquee 10s linear infinite;
      flex: 0 0 100%;
      display: flex;
      white-space: nowrap;
      padding-right: 20px;
    }

  .promotions__item {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  .promotions__currency {
    display: flex;
    align-items: center;
    margin-right: 10px;

    font-size: 16px;
    line-height: 110%;
  }

  .promotions__currency-number {
    margin-left: 17px;
    margin-right: 4px;
  }

  .promotions__pct {
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: 110%;
  }

  .promotions__pct--green {
    color: #44bc39;
    fill: #44bc39;
  }

  .promotions__pct--red {
    color: #d71a1a;
    fill: #d71a1a;
  }

  .promotions__pct--red .promotions__pct-icons {
      transform: rotate(-180deg);
      transform-origin: center;
    }

  .promotions__pct-icons {
    margin-right: 3px;
    width: 11px;
    height: 11px;
  }

  .promotions__pct-number {
    margin-right: 3px;
  }

  .promotions__pct-sign {
    margin-right: 4px;
  }

@media (max-width: 991.98px) {

.promotions--mobile {
    display: none
}
  }

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
