.btn {
  padding: 14px 24px 12px;

  font-size: 16px;
  line-height: 110%;
  color: #59358c;

  background: #ffffff;
  border: 1px solid #59358c;
  border-radius: 4px;
}

  @media (max-width: 479.98px) {.btn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 24px 18px
}
  }

.btn--purple {
  color: #ffffff;

  background: #59358c;

  transition: all 0.4s ease;
}

.btn--purple:hover {
    background: #8246b8;
  }
