.video-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 32px;

  background: #ffffff;
  border-radius: 50%;

  transition: all 0.4s ease;
  width: 104px;
  height: 104px;
}

  @media (max-width: 1200px) {.video-icon {
    width: calc(66px + (104 - 66) * ((100vw - 375px) / (1200 - 375)));
    height: calc(66px + (104 - 66) * ((100vw - 375px) / (1200 - 375)))
}
  }

  @media (max-width: 424px) {.video-icon {
    opacity: 0.8
}
  }

  .video-icon__triangle {
    width: 0;
    height: 0;
    margin-left: 8px;

    border-width: 16px 0 16px 26px;
    border-style: solid;
    border-color: transparent transparent transparent #d71a1a;

    transition: all 0.4s ease;
  }

.video-icon--subpost {
  width: 64px;
  height: 64px;
  margin: auto 21px 20px auto;
}

.video-icon--subpost .video-icon__triangle {
    margin-left: 4px;

    border-width: 10px 0 10px 18px;
  }
