.footer {
  background: #282828;
}

  .footer__content {
    padding: 53px 16px;
    display: grid;
    grid-template-columns: 177px 370px 370px;
    justify-content: space-between;
  }

  @media (max-width: 992px) {

  .footer__content {
      grid-template-columns: 177px 1fr 1fr;
      grid-template-rows: auto auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 16px
  }
    }

  @media (max-width: 992px) {

  .footer__info {
      order: 2;

      display: flex;
      flex-direction: column;
      align-items: center
  }
    }

  .footer__copy {
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.6px;
    color: #ffffff;
  }

  @media (max-width: 992px) {

  .footer__copy {
      margin-top: -8px;
      text-align: center
  }
    }

  @media (max-width: 480px) {

  .footer__copy {
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
      letter-spacing: 0.4px
  }
    }

  @media (max-width: 992px) {

  .footer__social {
      width: 200px;
      margin-left: 0
  }
    }
