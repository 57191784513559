.subpost {
  width: 370px;
  position: relative;
  flex-shrink: 0;
}

  .subpost._banner {
    width: 330px;
  }

  @media (max-width: 1200px) {.subpost {
    width: calc(311px + (370 - 311) * ((100vw - 375px) / (1200 - 375)))
}
  }

  .subpost__link {
    position: relative;
    display: flex;
    height: 220px;

    align-items: flex-end;
    flex-wrap: wrap;
  }

  .subpost__link:not(._banner)::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;

      background: linear-gradient(
        180deg,
        rgba(89, 53, 140, 0) 0%,
        #282828 100%
      );
    }

  .subpost__link:not(._banner):hover::after {
      background: linear-gradient(
        180deg,
        rgba(89, 53, 140, 0) 0%,
        #59358c 100%
      );
    }

  .subpost__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;

    z-index: 1;
  }

  .subpost__common {
    position: relative;
    z-index: 20;
    padding: 24px 18px;
  }

  .subpost__title {
    /* position: absolute;
        bottom: 18px;
        left: 24px;
        width: 300px; */

    font-weight: bold;
    font-size: 18px;

    line-height: 120%;

    color: #ffffff;

    z-index: 20;

    margin-bottom: 10px;
  }

  @media (max-width: 1200px) {

  .subpost__title {
      font-size: calc(22px + (24 - 22) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .subpost__date {
    z-index: 20;
    color: #fff;
    font-size: 14px;
  }

.subpost:hover .video-icon {
    background: #d71a1a;
  }

.subpost:hover .video-icon__triangle {
      border-color: transparent transparent transparent #ffffff;
    }
