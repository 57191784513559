.css-1s2u09g-control,
.css-1pahdxg-control {
  width: 138px;
  height: 44px;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.css-tlfecz-indicatorContainer {
  color: #59358c !important;
}

.css-6j8wv5-Input {
  font-size: 16px;
  line-height: 110%;
  font-family: MyriadPro;
  color: #686868 !important;

  opacity: 0.5;
}
