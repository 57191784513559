.footer-menu__title {
    margin-bottom: 24px;

    font-size: 14px;
    line-height: 110%;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #ffffff;
  }
  .footer-menu__list-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 170px);
    gap: 30px;
    align-items: start;
  }
  @media (max-width: 992px) {
  .footer-menu__list-wrapper {
      grid-template-columns: 1fr;
      grid-auto-flow: row
  }
    }
  .footer-menu__list-themes {
    display: grid;
    grid-template-columns: repeat(auto-fill, 170px);
    gap: 16px 30px;
  }
  .footer-menu__list {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
  }
  .footer-menu__tablet-lists-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 160px);
    gap: 24px;
    margin-bottom: 40px;
  }
  .footer-menu__link {
    font-size: 16px;
    line-height: 110%;

    color: #ffffff;
  }
