.quiz {
  position: relative;
  padding: 73px 56px 40px;
  padding-top: 73px;
  padding-bottom: 40px;
  padding-right: 56px;
  padding-left: 56px;

  color: #59358c;

  background: #f3e5ff;
}

  @media (max-width: 1200px) {.quiz {
    padding-top: calc(72px + (73 - 72) * ((100vw - 375px) / (1200 - 375)));
    padding-bottom: calc(32px + (40 - 32) * ((100vw - 375px) / (1200 - 375)));
    padding-right: calc(32px + (56 - 32) * ((100vw - 375px) / (1200 - 375)));
    padding-bottom: calc(32px + (56 - 32) * ((100vw - 375px) / (1200 - 375)))
}
  }

  .quiz__title {
    margin-bottom: 20px;

    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: inherit;
  }

  @media (max-width: 1200px) {

  .quiz__title {
      font-size: calc(22px + (24 - 22) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .quiz__form {
  }

  .quiz__list {
    margin-bottom: 25px;
  }

  @media (max-width: 479.98px) {

  .quiz__list {
      margin-bottom: 23px
  }
    }

  .quiz__item {
    margin-bottom: 17px;
  }

  .quiz__label {
    display: flex;
    font-size: 18px;

    line-height: 120%;

    color: inherit;
  }

  @media (max-width: 1200px) {

  .quiz__label {
      font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .quiz__input {
    display: none;
  }

  .quiz__input:checked + span::before {
    content: '';

    position: absolute;
    top: 3px;
    left: 3px;

    width: 12px;
    height: 12px;

    border-radius: 50px;

    background: #59358c;
  }

  .quiz span {
    position: relative;

    margin-right: 16px;

    width: 20px;
    height: 20px;

    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #59358c;

    flex-shrink: 0;
  }
