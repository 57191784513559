.footer-info {
  display: grid;
  grid-auto-flow: row;
  gap: 24px;
}

  .footer-info__ad {
    width: 100%;
    height: 44px;
    display: grid;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
  }

  .footer-info__logo img {
      width: 70px;
      height: 30px;
    }

  @media (max-width: 992px) {

  .footer-info__logo img {
        width: 50px;
        height: 20px
    }
      }

  @media (max-width: 992px) {

  .footer-info__logo {
      margin-top: 16px;
      justify-self: center
  }
    }

  @media (max-width: 992px) {.footer-info {
    max-width: 208px
}
  }
