.others {
  display: grid;

  column-gap: 30px;

  border-bottom: 1px solid rgba(104, 104, 104, 0.2);

  grid-template-columns: minmax(200px, 1070px);
  grid-template-rows: auto auto auto;
  padding-top: 32px;
  padding-bottom: 47px;
  row-gap: 32px;
}

  @media (max-width: 1200px) {.others {
    padding-top: calc(13px + (32 - 13) * ((100vw - 375px) / (1200 - 375)));
    padding-bottom: calc(13px + (47 - 13) * ((100vw - 375px) / (1200 - 375)))
}
  }

  @media (max-width: 479.98px) {.others {
    display: flex;
    flex-direction: column;

    border: none;

    row-gap: 18px
}
  }

  .others__wrapper {
    display: flex;

    grid-column: 1 / 4;
  }

  @media (max-width: 991.98px) {

  .others__wrapper {
      flex-direction: column;

      border-bottom: none
  }
    }

  .others__quiz {
    flex: 0 1 48.8%;
    margin-right: 30px;
  }

  @media (max-width: 991.98px) {

  .others__quiz {
      margin-right: 0;
      margin-bottom: 16px
  }
    }

  .others__post-video {
    flex: 0 1 48.8%;
    height: 100%;
  }

  @media (max-width: 991.98px) {
      .others__post-video .post-video__img {
        height: 429px;
      }
    }

  .others__promo {
    height: 100%;
  }

  @media (max-width: 479.98px) {

  .others__promo {
      height: 220px
  }
    }

  .others__materials {
    grid-column: 1 / 4;
  }

.others--page-article {
  display: flex;
  flex-direction: column;
  padding: 74px 0 80px;
}

.others--page-article .others__wrapper {
    order: 2;
  }

.others--page-article .others__btn {
    order: 2;
    margin-top: 12px;
  }

.others--page-article .materials__list {
    margin-bottom: 0;
  }

.materials__item._banner a {
  width: 343px;
  height: 346px;
  display: block;
}

.materials__item._banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.materials__item._banner._left a {
  margin-left: auto;
}

.materials__item._banner .materials__link:before {
  display: none;
}

@media (max-width: 926px) and (min-width: 767.98px) {
  .others.page__others .materials.others__materials .materials__item._banner {
    width: calc(50% - 15px);
    order: 1;
  }

  .others.page__others
    .materials.others__materials
    .materials__item._banner._left {
    order: 2;
  }

  .others.page__others .materials.others__materials .materials__item {
    order: 3;
  }
}

@media (max-width: 767.98px) {
  .others.page__others
    .materials.others__materials
    .materials__item:nth-child(2) {
    order: 2;
  }

  .others.page__others .materials.others__materials .materials__item._banner {
    width: 100%;
    order: 1;
  }

  .others.page__others
    .materials.others__materials
    .materials__item._banner._left {
    order: 3;
  }

  .others.page__others .materials.others__materials .materials__item {
    order: 4;
  }
}
