.subscription {
  display: flex;
  align-items: center;

  padding: 48px 56px;

  background: #f8f8f8;
}

  @media (max-width: 1200px) {.subscription {
    padding-left: calc(20px + (56 - 20) * ((100vw - 375px) / (1200 - 375)));
    padding-right: calc(20px + (56 - 20) * ((100vw - 375px) / (1200 - 375)));
    padding-top: calc(20px + (48 - 20) * ((100vw - 375px) / (1200 - 375)));
    padding-bottom: calc(20px + (48 - 20) * ((100vw - 375px) / (1200 - 375)))
}
  }

  @media (max-width: 1024px) {.subscription {
    flex-wrap: wrap;
    row-gap: 20px
}
  }

  .subscription__title {
    margin-right: 67px;
    width: 138px;

    font-weight: bold;
    font-size: 24px;
    line-height: 120%;

    color: #59358c;
  }

  @media (max-width: 1200px) {

  .subscription__title {
      margin-right: calc(10px + (67 - 10) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  @media (max-width: 1024px) {

  .subscription__title {
      width: 100%
  }
    }

  .subscription__text {
    margin-right: 67px;
    max-width: 296px;
    width: 100%;
    font-size: 16px;
    line-height: 130%;

    color: #282828;
  }

  @media (max-width: 1200px) {

  .subscription__text {
      margin-right: calc(10px + (67 - 10) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  @media (max-width: 1024px) {

  .subscription__text {
      width: 100%;
      max-width: 100%
  }
    }

  .subscription__form {
    display: flex;
    align-items: center;
  }

  @media (max-width: 600px) {

  .subscription__form {
      flex-direction: column;
      row-gap: 20px;
      align-items: flex-start
  }
    }

  .subscription__input {
    margin-right: 16px;
    padding: 14px 24px 12px;
    width: 248px;
    min-width: 248px;

    font-size: 16px;
    line-height: 110%;

    color: #686868;

    opacity: 0.5;

    border: 1px solid rgba(104, 104, 104, 0.6);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .subscription__btn {
    white-space: nowrap;
  }

.subscription--sidebar {
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 40px;
  padding-left: 40px;
}

@media (max-width: 1200px) {

.subscription--sidebar {
    padding-top: calc(10px + (20 - 10) * ((100vw - 375px) / (1200 - 375)));
    padding-bottom: calc(10px + (20 - 10) * ((100vw - 375px) / (1200 - 375)));
    padding-right: calc(0px + (40 - 0) * ((100vw - 375px) / (1200 - 375)));
    padding-bottom: calc(0px + (40 - 0) * ((100vw - 375px) / (1200 - 375)))
}
  }

.subscription--sidebar .subscription__title {
      margin-right: 0;
      margin-bottom: 19px;
      width: 100%;
      font-size: 24px;
      line-height: 33px;
    }

@media (max-width: 1200px) {

.subscription--sidebar .subscription__title {
        margin-bottom: calc(
          -10px + (99 - -10) * ((100vw - 375px) / (1200 - 375))
        );
        font-size: calc(19px + (24 - 19) * ((100vw - 375px) / (1200 - 375)));
        line-height: calc(23px + (33 - 23) * ((100vw - 375px) / (1200 - 375)))
    }
      }

.subscription--sidebar .subscription__form {
      flex-direction: column;
      width: 100%;
    }

.subscription--sidebar .subscription__input {
      margin-right: 0;
      margin-bottom: 16px;
      width: 100%;
    }

.subscription--sidebar .subscription__text {
      margin-right: 0;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 21px;
    }

@media (max-width: 1200px) {

.subscription--sidebar .subscription__text {
        font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1200 - 375)));
        line-height: calc(17px + (21 - 17) * ((100vw - 375px) / (1200 - 375)))
    }
      }

.subscription--sidebar .subscription__btn {
      width: 100%;
      margin-right: 0;
      padding-top: 22px;
      padding-bottom: 20px;
      padding-right: 24px;
      padding-left: 24px;
    }

@media (max-width: 1200px) {

.subscription--sidebar .subscription__btn {
        padding-top: calc(15px + (22 - 15) * ((100vw - 375px) / (1200 - 375)));
        padding-bottom: calc(
          25px + (20 - 25) * ((100vw - 375px) / (1200 - 375))
        );
        padding-right: calc(
          24px + (24 - 24) * ((100vw - 375px) / (1200 - 375))
        );
        padding-left: calc(24px + (24 - 24) * ((100vw - 375px) / (1200 - 375)))
    }
      }

.btn--purple[disabled] {
  opacity: 0.2;
}

.confirm-subscribe {
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  line-height: 130%;
  color: #282828;
}
