.menu__list {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 8px;

    background: #282828;
  }

    @media (max-width: 1200px) {.menu__list {
      justify-content: space-between
  }
    }
  .menu__item {
    position: relative;
  }
  .menu__item:hover {
      background: #59358c;
    }
  .menu__item:hover .submenu-items {
        display: block;
      }
  .menu__link {
    display: flex;
    align-items: center;
    height: 44px;

    padding: 0 16px;

    font-size: 14px;
    line-height: 110%;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #ffffff;

    transition: all 0.1s linear;
  }
  @media (max-width: 1200px) {
  .menu__link {
      /* padding-left: calc(5px + (16 - 5) * ((100vw - 768px) / (1200 - 768)));
            padding-right: calc(5px + (16 - 5) * ((100vw - 768px) / (1200 - 768))); */
  }
    }
  @media (max-width: 1200px) {
  .menu__link {
      font-size: 12px
  }
    }
  .menu__link--arrow {
    position: relative;
    padding-right: 34px;
  }
  @media (max-width: 1200px) {
  .menu__link--arrow {
      padding-right: 34px
  }
    }
  .menu__link--arrow:after {
      content: '';

      position: absolute;
      top: 17px;
      right: 19px;
      width: 5px;
      height: 5px;

      border-top: 1px solid #ffffff;
      border-right: 1px solid #ffffff;

      transform: rotate(135deg);
    }

@media (max-width: 991.98px) {

.menu--mobile {
    display: none
}
  }
