.subscription__form .сheckbox._default {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: #59358c;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 14px;
}

.subscription__form .сheckbox._default .сheckbox__input {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.subscription__form .сheckbox._default .сheckbox__icon {
  position: relative;
  margin-right: 12px;
  width: 20px;
  height: 20px;
  border: 1px solid #59358c;
  border-radius: 4px;
  flex-shrink: 0;
}

.subscription__form
  .сheckbox._default
  .сheckbox__input:checked
  + .сheckbox__icon::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 3px;
  border-radius: 2px;
  background: #59358c;
}

.subscription__form {
  flex-wrap: wrap;
}

.subscription__form .subscription__categories {
  width: 100%;
  display: flex;
}

.subscription--sidebar
  .subscription__form
  .subscription__categories
  .сheckbox._default {
  width: 100%;
  margin-right: 0;
}

.subscription__form .subscription__fields {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.subscription--sidebar .subscription__form .subscription__categories,
.subscription--sidebar .subscription__form .subscription__fields {
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .subscription__form .subscription__fields {
    flex-direction: column;
  }

  .subscription__form {
    width: 100%;
  }

  .subscription__input {
    margin-bottom: 10px;
    width: 100%;
  }
}
