.main {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

  @media (max-width: 1200px) {.main {
    row-gap: calc(16px + (32 - 16) * ((100vw - 375px) / (1200 - 375)))
}
  }

  .main__subpost-list {
    display: flex;
    padding: 30px;
    justify-content: space-between;
    /* column-gap: 70px; */

    margin-left: -10px;
    margin-right: -10px;
    padding: 0 10px;
    overflow-y: auto;
  }

  .main__subpost-list:-webkit-scrollbar {
      display: none;
    }

  @media (max-width: 1200px) {

  .main__subpost-list {
      column-gap: calc(16px + (30 - 16) * ((100vw - 375px) / (1200 - 375)))
  }
    }
