.post {
  display: flex;
  position: relative;
  color: #ffffff;
}

  @media (max-width: 991.98px) {.post {
    flex-direction: column
}
  }

  .post:hover .post__content {
    background: #8246b8;
  }

  .post__content {
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    padding: 94px 56px 48px;

    max-width: 470px;
    width: 100%;

    background: #59358c;

    transition: all 0.2s linear;
  }

  @media (max-width: 991.98px) {

  .post__content {
      order: 2;
      max-width: 100%;
      padding-top: 54px;
      padding-bottom: 48px;
      padding-right: 56px;
      padding-left: 56px
  }

      @media (max-width: 1200px) {

  .post__content {
        padding-top: calc(32px + (54 - 32) * ((100vw - 375px) / (1200 - 375)));
        padding-bottom: calc(
          32px + (48 - 32) * ((100vw - 375px) / (1200 - 375))
        );
        padding-left: calc(32px + (56 - 32) * ((100vw - 375px) / (1200 - 375)));
        padding-right: calc(
          32px + (56 - 32) * ((100vw - 375px) / (1200 - 375))
        )
  }
      }
    }

  .post__title {
    margin-bottom: 24px;

    font-weight: bold;
    font-size: 24px;

    line-height: 120%;

    color: inherit;
  }

  @media (max-width: 1200px) {

  .post__title {
      font-size: calc(22px + (32 - 22) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .post__text {
    margin-bottom: 87px;
    font-size: 18px;

    line-height: 140%;

    color: inherit;
  }

  @media (max-width: 1200px) {

  .post__text {
      font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  @media (max-width: 991.98px) {

  .post__text {
      margin-bottom: 57px;
      margin-bottom: calc(40px + (57 - 40) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .post__images {
    max-width: 700px;
    width: 100%;
  }

  @media (max-width: 991.98px) {

  .post__images {
      order: 1;
      max-width: 100%;
      height: 330px;
      height: calc(200px + (330 - 200) * ((100vw - 375px) / (1200 - 375)))
  }
    }

  .post__images img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

.post--articles .post__content {
      padding: 38px 56px 32px;

      max-width: 370px;
      width: 100%;
    }

@media (max-width: 991.98px) {

.post--articles .post__content {
        max-width: 100%;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-right: 32px;
        padding-left: 32px
        /* padding-top: calc(
                    32px + (48 - 32) * ((100vw - 375px) / (1200 - 375))
                );
                padding-bottom: calc(
                    24px + (48 - 24) * ((100vw - 375px) / (1200 - 375))
                );
                padding-right: calc(
                    32px + (56 - 32) * ((100vw - 375px) / (1200 - 375))
                );
                padding-left: calc(
                    32px + (56 - 32) * ((100vw - 375px) / (1200 - 375))
                ); */
    }
      }

@media (max-width: 991.98px) {

.post--articles .post__content .more {
          font-size: 14px
      }
        }

.post--articles .post__title {
      margin-bottom: 28px;
      font-size: 24px;
      line-height: 120%;
    }

@media (max-width: 1200px) {

.post--articles .post__title {
        font-size: calc(22px + (24 - 22) * ((100vw - 375px) / (1200 - 375)))
    }
      }

@media (max-width: 991.98px) {

.post--articles .post__title {
        font-size: 18px;
        line-height: 22px
    }
      }

.post--articles .post__text {
      margin-bottom: 45px;
      font-size: 16px;
      line-height: 130%;
    }

@media (max-width: 991.98px) {

.post--articles .post__images {
        height: 200px
    }
      }

.post--articles-yellow:hover .post__content {
    background: #ffc56e;
  }

.post--articles-yellow .post__content {
    background: #ffc56e;
    color: #282828;
  }

.post--articles-yellow .more {
    color: #282828;
    fill: #282828;
  }

/* QUOTE AUTHOR */

.other-article--quoteAuthor .other-article__img,
.editor-card-quoteAuthor .other-article__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.quote-author {
  background: #f3e5ff;
  padding: 32px 40px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.quote-author .article-main__other-article {
  margin: 0;
}

.quote-author__image {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin: 0 auto 16px;
}

.quote-author__common {
  text-align: center;
  width: 200px;
  min-width: 200px;
}

.quote-author__name {
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  color: #59358c;
  margin-bottom: 8px;
}

.quote-author__place {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  color: #686868;
  opacity: 0.5;
}

/* .quote-author {
    margin-bottom: 60px;
}

.quote-author__image {
  width: 146px;
  height: 146px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 12px;
  display: inline-block;
}

.quote-author .other-article {
  height: 146px;
  margin: 0;
}

.quote-author__name {
  font-size: 23px;
  color: #8B559E;
  max-width: 250px;
}

.quote-author__place {
  font-size: 14px;
  line-height: 1.42857;
  color: #5c5c5c;
  max-width: 250px;
}

.quote-author {
  clear: both;
}

.quote-author:after,
.quote-author:before {
  content: '';
  clear: both;
  display: table;
}

.quote-author__text {
  color: #000;
  margin-bottom: 30px;
  display: inline;
}

.quote-author__common {
  float: right;
  text-align: right;
  margin-left: 55px;
} */

.quote-author .other-article__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.quote-author .other-article:before,
.quote-author .other-article:after {
  display: none;
}

/* .quote-author__text {
  width: 100%;
} */

.qa,
.c-content-detail-block7-a {
  margin-bottom: 30px;
}

.qa__title,
.c-content-detail-block7-q {
  font-weight: bold;
  margin-bottom: 16px;
  color: #8b559e;
}
